@import "Atoms/components";
@import "Molecules/components";
@import "Organisms/components";
@import "Templates/components";
@import "Atoms/_atomic/atomic";

.banners-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;

    @media (max-width: 768px) {
        grid-gap: 30px;
    }

    @media (max-width: 576px) {
        grid-gap: 15px;
        grid-template-columns: repeat(1, 1fr);
    }

    &__item {
    }

    &__heading {
        text-transform: lowercase;
        margin-top: 18px;
        font-weight: 600;
        line-height: 1.3;

        &:first-letter {
            text-transform: uppercase !important;
        }
    }

    &__desc {
        line-height: 22px;
        font-style: 600;
        font-size: 18px;
    }
}

.socials {
    display: flex;
    flex-wrap: wrap;

    &__link {
        width: 48px;
        height: 48px;
        display: inline-block;

        @media (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        margin-bottom: 15px;

        &:not(:last-of-type) {
            margin-right: 15px;
        }

        & embed {
            pointer-events: none;
        }

        & svg {
            width: 100%;
            height: 100%;
        }
    }
}

.breadcrumbs.container {
    margin-top: 20px;
    margin-bottom: 10px;
}

.button--add-to {
    background: $brotherstore !important;

    & svg {
        fill: #fff !important;
    }

    &:before,
    &:after {
        background: $brotherstore !important;
    }
}

.button--tocompare,
.button--icon.towishlist,
.product-view__button,
.wishlist__item-actions .button--icon,
.button.product-view__add-to-cart,
.minicart-product__button,
.button.cart-list-item__action {
    & svg {
        fill: $color-primary !important;
    }

    &:before,
    &:after {
        background-color: $brotherstore !important;
    }

    &:hover {
        & svg {
            fill: #fff !important;
        }
    }
}

.input__button-pass {
    &:before,
    &:after {
        background-color: $brotherstore !important;
    }

    &:hover {
        & svg {
            fill: #fff !important;
        }

        &:before,
        &:after {
            background-color: $brotherstore !important;
        }
    }
}

.button--secondary,
.minicart-content__action-button,
.shipping-address__btn-remind,
a.action.primary.continue,
button.action.primary.continue,
button.action-cancel {
    color: $brotherstore !important;
    border-color: $brotherstore !important;

    &:hover {
        background-color: $brotherstore !important;
        border-color: $brotherstore !important;
        color: #fff !important;
    }

    &:before,
    &:after {
        background-color: $brotherstore !important;
    }

    &.minicart-content__edit {
        background-color: transparent !important;
        color: $brotherstore !important;

        &:hover {
            color: #fff !important;
        }
    }

    &.newsletter__button {
        color: #fff !important;
    }
}

.toolbar__sorter-link,
.add-to-cart__button-more {
    svg {
        fill: $color-primary !important;
    }

    &:hover {
        svg {
            fill: #fff !important;
        }

        &,
        &:before,
        &:after {
            background-color: $brotherstore !important;
        }
    }
}

.quantity-update__button--plus,
.quantity-update__button--minus {
    svg {
        fill: $color-primary !important;
    }

    &:after,
    &:before {
        background-color: $brotherstore !important;
    }

    &:hover {
        & svg {
            fill: #fff !important;
        }
    }
}

button.header-button {
    &:hover {
        & svg {
            fill: $brotherstore !important;

            & path {
                fill: $brotherstore !important;
            }
        }
    }
}

// .button--fluid,
.minicart-content__action-button,
.wishlist__button,
.actions-group__button[name="do"],
#reviewAddButton,
button.action.primary.checkout,
.button.checkout,
.action-accept,
.account-form__button,
button.action.action-apply,
.shipping-address__btn-login,
.forgot-password__actions button[type="submit"],
.cart-discount__content-button--apply,
button[role="add-address"],
button.actions-group__button[type="submit"],
.product-view__update-cart,
.action-save-address,
.actions-group__button.save,
.search-no-results__link,
.compare__cart,
.cookie-consent-accept-button,
button.action-update,
.shipping-method-buttons-container button.continue,
.action.primary[data-bind="attr: { href: getUrl() }"] {
    color: #fff !important;
    border-color: $brotherstore !important;
    background-color: $brotherstore !important;

    &:hover {
        background-color: $brotherstore !important;
    }

    &:before,
    &:after {
        background-color: $brotherstore !important;
    }
}

.pager__link {
    &:hover {
        & svg {
            fill: #fff !important;
        }
    }
}

a.action.primary.continue,
.action.primary[data-bind="attr: { href: getUrl() }"] {
    margin: 15px;
    width: fit-content;
}

.cart-discount__content-button {
    background-color: $brotherstore !important;

    &:after,
    &:before {
        background-color: $brotherstore !important;
    }
}

.footer__scroll-top {
    &:hover {
        & svg {
            fill: #fff !important;

            & path {
                fill: #fff !important;
            }
        }
    }
}

.owl-item {
    width: 100vw !important;
}

.loader__icon {
    border: 8px solid $brotherstore !important;
    border-top-color: transparent !important;
}

.header {
    border: none !important;
}

.header__minicart {
    padding-left: 0 !important;
}

.catalog-list .section__heading.search-section {
    display: none !important;
}

.catalog-list .input__field.search-form__input {
    margin-bottom: 12px;
    height: 50px !important;
}

.catalog-list .search-form__button {
    height: 48px !important;
}

.side-menu__close-button-text {
    font-family: "Halvetica-Neue", sans-serif;
}

.cart .order-summary {
    #shipping-zip-form {
        display: none;
    }
}

.shipping-estimation__title-icon {
    display: none !important;
}

div[name*="country_id"] {
    display: none !important;
}

.payu-payment__logo {
    width: 160px;
    max-height: initial !important;
    margin-right: 5px !important;
}

div[name="shippingAddress.company"],
div[name="shippingAddress.vat_id"] {
    display: none;
}

.admin__control-fields {
    & div[name="shippingAddress.street.2"],
    & div[name="shippingAddress.street.1"] {
        display: none !important;
    }

    & div[name*=".street."] label span {
        display: none !important;
    }
}

.price-container {
    display: flex;
    flex-flow: column nowrap;
}

.price-excluding-tax {
    line-height: 18px !important;

    & .price {
        font-weight: 400 !important;
        font-size: 14px !important;

        &:after {
            content: "netto";
            opacity: 0.67;
            margin-left: 5px;
        }
    }
}

.product-info-stock-sku {
    display: none !important;
}

.review.review--summary.empty {
    display: none !important;
}

.product-view__rating {
    justify-content: flex-end !important;
}

.add-to-cart__button-more {
    display: none !important;
}

*[aria-label="Add to compare"] {
    display: none !important;
}

.heading--page.product-view__title {
    line-height: 1.2 !important;
}

.add-to-cart__wrapper {
    padding: 8px 16px !important;
}

//.categorypath-do-drukarek-etykiet {
//    #search-eksploatacja {
//        display: inline-block !important;
//    }
//    #search {
//        display: none;
//    }
//
//}
.categorypath-do-urzadzen-laserowych-i-led,
.categorypath-do-drukarek-etykiet,
.categorypath-do-urzadzen-atramentowych {
    & .toolbar__mode,
    & .select.toolbar__sorter,
    & .toolbar__amount,
    & .select.toolbar__limiter {
        display: none !important;
    }

    & #page-title-heading {
        &:before {
            content: "materiały eksploatacyjne";
            line-height: 1;
            text-decoration: uppercase;
        }
    }
}

.categorypath-papiery,
.categorypath-akcesoria {
    & .search-form__input-wrapper {
        display: none !important;
    }
}

.toolbar.toolbar-products {
    border-bottom-width: 0;
}

.mp-banner-slider {
    display: none;
}

.cms-index-index {
    & .mp-banner-slider {
        display: block !important;
    }
}

.important-dialog-information {
    overflow-x: hidden;

    & > h2 {
        font-size: 18px;
        text-align: center;
        margin-top: 10px !important;
        margin-bottom: 5px !important;
        font-weight: 700;
        width: fit-content !important;
        margin-left: auto !important;
        margin-right: auto !important;
        white-space: nowrap;

        &.slide {
            animation: slide 12s infinite linear;
        }

        & > p {
            font-weight: inherit;
            white-space: inherit;
        }
    }

    @keyframes slide {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }
}

.cookie-consent-background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    display: none;
}

.cookie-consent-wrapper {
    display: none;
    position: fixed;
    bottom: 0;
    box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.15);
    padding: 15px;
    background: #fff;

    margin: 0 auto;
    width: 100%;
    height: 80px;
    z-index: 10000;

    @media (max-width: 610px) {
        height: 146px;
    }

    p {
        padding-right: 70px;
        @media (max-width: 1270px) {
            br {
                display: none;
            }
        }
        @media (max-width: 1100px) {
            font-size: 12px;
        }
    }
    div.cookie-buttons-group {
        position: absolute;
        top: 16px;
        right: 10px;
    }
}

#filters-option-title.modal__heading.filters__heading {
    display: none !important;
}

// inpost ______________________

#widget-modal {
    overflow: unset !important;

    & #widget-modal__topbar,
    & .search-group {
        background-color: $brotherstore;
    }

    & #easypack-search {
        padding: 5px 10px;
    }

    & #widget-modal__map {
        overflow: unset !important;
    }

    & .easypack-widget.mobile .view-chooser .list-wrapper[data-active="true"],
    .easypack-widget.mobile .view-chooser .map-wrapper[data-active="true"] {
        background-color: $brotherstore;
    }

    & .easypack-widget.mobile .view-chooser .list-wrapper[data-active="true"],
    .easypack-widget.mobile .view-chooser .map-wrapper[data-active="true"] {
        background-color: $brotherstore;
    }

    & .links {
        & a {
            border-color: $brotherstore;

            &.select-link {
                background-color: $brotherstore;
            }
        }
    }

    & .types-list {
        margin: 10px 0;
        display: flex;
        justify-content: center;

        & > li:not(:last-child) {
            margin-right: 15px;
        }

        & > li {
            margin: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-position-x: 0;
            padding: 0;
            padding-left: 30px;

            & > *:not(:last-child) {
                margin-right: 5px;
            }

            & .has-tooltip {
                padding-left: 0;
            }
        }
    }
}

.filter--checkbox .more-info {
    display: none !important;
}

.button.search-no-results__link {
    display: flex !important;
    margin: 0 auto !important;
}

.catalog-grid .product-grid-item__image-wrapper .ratio-container {
    padding-bottom: 80% !important;
}

.product-grid-item__name {
    min-height: auto !important;
}

.header-button__counter-dot {
    width: 12px !important;
    height: 11px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
}

.secondary-checkout-headline {
    font-weight: bold;
    font-size: 20px;
}

.checkout-addnotation {
    font-style: italic;
}

span[data-bind="i18n: 'Email Address'"] {
    &:after {
        content: " do realizacji zamówienia";
    }
}

.contact-index-index .row.center-xs > div > p {
    text-align: center !important;
}

.catalog-list {
    flex-flow: column nowrap !important;
}

button.product-view__stock {
    display: none !important;
}

.dashboard-nav .dashboard-nav__section.margin-top-xl {
    display: none !important;
}

.table-order-items td {
    justify-content: space-between;
}

.table-order-items th:first-of-type {
    white-space: nowrap;
}

.order-products-toolbar .toolbar,
.order-products-toolbar {
    border-bottom: none !important;
}

#dashboard-content .list.margin-bottom-l {
    display: none;
}

#search {
    @media (max-width: 425px) {
        &::-webkit-input-placeholder {
            color: white;
        }
        &::-moz-placeholder {
            color: white;
            opacity: 1;
        }
        &:-moz-placeholder {
            color: white;
            opacity: 1;
        }
        &::-ms-input-placeholder {
            color: white;
        }
    }
}

.row.success-page .link--button {
    margin-bottom: 15px;
}

.dashboard-form__divider {
    & .country,
    & .region {
        display: none !important;
    }
}

.minicart-product__field.cart-item-qty {
    max-width: 65px !important;
}

.minicart-content__products-list {
    @media (max-width: 768px) {
        margin-bottom: 110px;
    }
}

.checkout-button-100 {
    width: 100%;
}
.payment-method {
    opacity: 0.5;
    pointer-events: none;
}
.payment-method.enable {
    opacity: 1;
    pointer-events: auto;
}
.payment-method.hidden-payment-method {
    display: none !important;
}

.order-summary__list-item {
    margin-bottom: 12px;
}

.totals-tax,
.grand.totals {
    th {
        padding-right: 25px !important;
        text-align: left;
    }
}

.payu-payment {
    & .method__list {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media (min-width: 425px) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @media (min-width: 576px) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        @media (min-width: 768px) {
            display: block;
        }
    }
}

ul.order-summary__list {
    & .order-summary__list-item:nth-child(2) {
        display: none;
    }
}

.order-comment-form {
    & .label {
        display: none;
    }

    & textarea {
        width: 100% !important;
        height: 130px;
        resize: none;
    }
}

.toolbar__sorter
{
    display: none;
}

.cart-list-item__action_description {
    font-size: 13px;
    margin-right: 3px;
    margin-top: 13px;
}

.payu-payment__logo {
    max-height: 25px !important;
    width: auto !important;
}

.modal-popup-top p.cart-buttons {
    text-align: right;
    margin-top: 30px;
}
.modal-popup-body {
    height: auto !important;
    padding-bottom: 10px;
}
.no-header-footer .modal-inner-wrap {
    border-radius: 0px !important;
}


/*
 *   SPARSH PRODUCT LABELS
 */
.catalog-grid{
    .product-item-info {
        position: relative;
        .sparsh-product-label {
            top: 0px;
            right: 0px;
            padding: 10px 25px;
            word-wrap: break-word;
        }
        .sparsh-product-label.rectangle {
            position: absolute;
            z-index: 2;
        }
        .sparsh-product-label.oval {
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            border-radius: 50%;
            position: absolute;
            z-index: 2;
        }
        .sparsh-product-label-image {
            position: absolute;
            top: 0px;
            right: 0px;
            z-index: 2;
            width: 60px;
        }
    }
}
.product-info-main {
    //position: relative;
    .sparsh-product-label {
        top: 0px;
        left: 0px;
        padding: 10px 25px;
        word-wrap: break-word
    }
    .sparsh-product-label-image {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        width: 80px;
    }
    @media screen and (min-width:1024px) {
        .sparsh-product-label, .sparsh-product-label-image {
            left: 82px;
            top: 12px;
        }
    }
    @media screen and (min-width:1200px) {
        .sparsh-product-label, .sparsh-product-label-image {
            left: 114px;
        }
    }
    .sparsh-product-label.rectangle {
        position: absolute;
        z-index: 2;
    }
    .sparsh-product-label.oval {
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        position: absolute;
        z-index: 2;
    }
}

@media screen and (max-width:767px) {
    .products-grid{
        .product-item-info {
            .sparsh-product-label {
                padding: 3px 15px;
            }
            .sparsh-product-label-image {
                width: 50px;
            }
        }
    }
    .product.media {
        .sparsh-product-label {
            padding: 3px 15px;
        }
        .sparsh-product-label-image {
            width: 50px;
        }
    }
}

fieldset.field.street .input {
    margin-bottom: 0 !important;
}

.banners-grid__item a {
    display: block;
    :hover {
        text-decoration: none;
    }
}
.banners-grid__item h2 a {
    text-transform: none !important;
    font-weight: inherit;
    font-size: inherit;
}

.product-view__stock-status span::before {
    content: ' ';
    width: 8px;
    height: 8px;
    display: inline-block;
    background-color: lime;
    margin-bottom: 1px;
    margin-right: 5px;
}

.product-view__stock-status--alert span::before {
    background-color: red;
}

@media screen and (min-width:1024px) {
    .category-sidebar-banner {
        width: 234px !important;

        .mp-banner-slider {
            display: block;

            .owl-item {
                width: 234px !important;
            }
        }
    }
}
@media screen and (min-width:1200px) {
    .category-sidebar-banner {
        width: 294px !important;

        .mp-banner-slider {
            display: block;

            .owl-item {
                width: 294px !important;
            }
        }
    }
}

.modalMapInPost .type-filter {
    background-color: white;
}

.inpost-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 10000;
}
.inpost-modal__container {
    height: calc(100% - 40px);
    margin: 20px;
}

/*
Amasty GDPR Cookie
 */


/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.login-container .g-recaptcha,
.form-login .g-recaptcha,
.form-edit-account .g-recaptcha {
    margin-bottom: 10px !important;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.required-captcha.checkbox {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.block.newsletter .field-recaptcha .field .control:before {
    content: none;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.review-form .field-recaptcha {
    margin-bottom: 10px;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.form.send.friend .g-recaptcha {
    margin-top: 40px;
}
.amslick-slider-container {
    /* phpcs:ignore Magento2.Less.SemicolonSpacing.NotAtEnd */
}
.amslick-slider-container .slick-dots li button:focus,
.amslick-slider-container .slick-dots li button:hover,
.amslick-slider-container .slick-list:focus,
.amslick-slider-container .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus,
.amslick-slider-container .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover,
.amslick-slider-container .slick-dots li .action-gift:focus,
.amslick-slider-container .slick-dots li .action-gift:hover {
    outline: 0;
}
.amslick-slider-container .slick-list,
.amslick-slider-container .slick-slider,
.amslick-slider-container .slick-track {
    display: block;
    position: relative;
}
.amslick-slider-container .slick-loading .slick-slide,
.amslick-slider-container .slick-loading .slick-track {
    visibility: hidden;
}
.amslick-slider-container .slick-list {
    margin: 0;
    overflow: hidden;
    padding: 0;
}
.amslick-slider-container .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.amslick-slider-container .slick-slider .slick-list,
.amslick-slider-container .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
}
.amslick-slider-container .slick-track {
    left: 0;
    margin: 0 auto;
    top: 0;
}
.amslick-slider-container .slick-track:after,
.amslick-slider-container .slick-track:before {
    content: '';
    display: table;
}
.amslick-slider-container .slick-track:after {
    clear: both;
}
.amslick-slider-container .slick-slide,
.amslick-slider-container .slick-slide.slick-loading img {
    display: none;
}
.amslick-slider-container .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    vertical-align: middle;
}
.amslick-slider-container [dir=rtl] .slick-slide {
    float: right;
}
.amslick-slider-container .slick-slide.dragging img {
    pointer-events: none;
}
.amslick-slider-container .slick-vertical .slick-slide {
    border: 1px solid transparent;
    display: block;
    height: auto;
}
.amslick-slider-container .slick-arrow.slick-hidden {
    display: none;
}
.amslick-slider-container .slick-loading .slick-list {
    background: 0 0;
    position: relative;
}
.amslick-slider-container .slick-loading .slick-list:before {
    animation: loading 1s infinite linear;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    border-style: solid;
    border-top-color: #5c5c5c;
    border-width: 3px;
    content: '';
    display: block;
    height: 25px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 25px;
}
.amslick-slider-container .slick-next.slick-disabled,
.amslick-slider-container .slick-prev.slick-disabled {
    opacity: 0.25;
}
.amslick-slider-container .slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.amslick-slider-container .slick-dots {
    display: block;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}
.amslick-slider-container .slick-dots li {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    position: relative;
    width: 20px;
}
.amslick-slider-container .slick-dots li button,
.amslick-slider-container .slick-slider .slick-arrow,
.amslick-slider-container .slick-dots li .cart.table-wrapper .actions-toolbar > .action,
.amslick-slider-container .slick-dots li .action-gift {
    box-shadow: none;
    cursor: pointer;
    display: block;
    outline: 0;
}
.amslick-slider-container .slick-dots li button,
.amslick-slider-container .slick-dots li .cart.table-wrapper .actions-toolbar > .action,
.amslick-slider-container .slick-dots li .action-gift {
    background: 0 0;
    border: 0;
    color: transparent;
    font-size: 0;
    height: 20px;
    line-height: 0;
    padding: 5px;
    width: 20px;
}
.amslick-slider-container .slick-dots li button:focus:before,
.amslick-slider-container .slick-dots li button:hover:before,
.amslick-slider-container .slick-dots li .cart.table-wrapper .actions-toolbar > .action:focus:before,
.amslick-slider-container .slick-dots li .cart.table-wrapper .actions-toolbar > .action:hover:before,
.amslick-slider-container .slick-dots li .action-gift:focus:before,
.amslick-slider-container .slick-dots li .action-gift:hover:before {
    opacity: 1;
}
.amslick-slider-container .slick-dots li button:before,
.amslick-slider-container .slick-dots li .cart.table-wrapper .actions-toolbar > .action:before,
.amslick-slider-container .slick-dots li .action-gift:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #000000;
    content: 'â€¢';
    font-size: 16px;
    height: 20px;
    left: 0;
    line-height: 20px;
    opacity: 0.25;
    position: absolute;
    text-align: center;
    top: 0;
    width: 20px;
}
.amslick-slider-container .slick-dots li.slick-active button:before,
.amslick-slider-container .slick-dots li.slick-active .cart.table-wrapper .actions-toolbar > .action:before,
.amslick-slider-container .slick-dots li.slick-active .action-gift:before {
    color: #000000;
    opacity: 0.75;
}
.amslick-slider-container .slick-slider {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    touch-action: pan-y;
    user-select: none;
}
.amslick-slider-container .slick-slider .slick-arrow {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjQzRDNEM0Ii8+PC9zdmc+') center no-repeat !important;
    border: none;
    color: transparent;
    font-size: 0;
    height: 22px;
    line-height: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: background .3s ease;
    width: 15px;
}
.amslick-slider-container .slick-slider .slick-arrow:before,
.amslick-slider-container .slick-slider .slick-arrow:after {
    content: '';
}
.amslick-slider-container .slick-slider .slick-arrow:hover {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0uMi40QTEgMSAwIDAgMSAxLjYuMmwxMiAxMGExIDEgMCAwIDEgMCAxLjZsLTEyIDEwYTEgMSAwIDAgMS0xLjItMS42TDExLjMzMyAxMSAuNCAxLjhBMSAxIDAgMCAxIC4yLjR6IiBmaWxsPSIjMTYxNjE2Ii8+PC9zdmc+') center no-repeat;
}
.amslick-slider-container .slick-slider .slick-arrow.slick-next {
    right: 0;
}
.amslick-slider-container .slick-slider .slick-arrow.slick-prev {
    left: 0;
    transform: rotate(180deg) translate(0, 50%);
}
.amslick-slider-container .slick-initialized .slick-slide {
    display: inline-block !important;
    float: none !important;
    height: auto;
}
.amslick-slider-container .product-items.slick-slider > .slick-list {
    padding: 15px 0;
}
.amslick-slider-container .product-items.slick-slider .slick-slide .product-item {
    display: block !important;
    margin: auto !important;
    max-width: 205px;
}
.amslick-slider-container .sidebar.sidebar-additional {
    width: 100%;
}
@keyframes loading {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }
    100% {
        transform: translate(-50%, -50%) rotate(359.9deg);
    }
}
.amgdprcookie-cookie-container {
    margin: 0 10px 20px 0;
    padding: 0 0 25px 0;
}
.amgdprcookie-cookie-container.-modal:first-child {
    padding-top: 25px;
}
.amgdprcookie-cookie-container.-modal .amgdprcookie-header,
.amgdprcookie-cookie-container.-modal .amgdprcookie-text {
    padding-left: 25px;
}
.amgdprcookie-cookie-container.-modal {
    box-shadow: none;
    direction: ltr;
    margin-right: 30px;
}
.amgdprcookie-cookie-container .amgdprcookie-header {
    display: flex;
    justify-content: space-between;
}
.amgdprcookie-cookie-container .amgdprcookie-caption {
    font-size: 16px;
    font-weight: bold;
    line-height: 27px;
    margin: 0 0 15px;
}
.amgdprcookie-cookie-container .amgdprcookie-text {
    color: #363636;
    font-size: 12px;
    margin-bottom: 10px;
}
.amgdprcookie-cookie-container .amgdprcookie-options {
    color: #4670c9;
    cursor: pointer;
    font-size: 12px;
}
.amgdprcookie-toggle-cookie {
    padding-left: 50px;
    position: relative;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input {
    height: 0;
    opacity: 0;
    width: 0;
}
.amgdprcookie-toggle-cookie .amgdprcookie-label {
    background: #a5a5a5;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    height: 29px;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
}
.amgdprcookie-toggle-cookie .amgdprcookie-label:after {
    background: #ffffff;
    border-radius: 40px;
    content: '';
    height: 25px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: all .4s ease;
    width: 25px;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:active + .amgdprcookie-label,
.amgdprcookie-toggle-cookie .amgdprcookie-input:focus + .amgdprcookie-label {
    box-shadow: 0 0 3px 1px #00699d;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:disabled + .amgdprcookie-label {
    background: #e8e8e8;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:disabled + .amgdprcookie-label:after {
    left: 95%;
    transform: translateX(-100%);
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
    background: #1979c3;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label:after {
    left: 95%;
    transform: translateX(-100%);
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:not([checked]) + .amgdprcookie-label {
    background: #a5a5a5;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:disabled + .amgdprcookie-label {
    background: #e8e8e8;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:disabled + .amgdprcookie-label:after {
    left: 2px;
}
.amgdprcookie-toggle-cookie .amgdprcookie-input:focus + .amgdprcookie-label {
    box-shadow: 0 0 3px 1px #00699d;
}
.amgdprcookie-main-wrapper {
    color: #363636;
}
.amgdprcookie-main-wrapper .amgdprcookie-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.amgdprcookie-main-wrapper .amgdprcookie-head {
    font-size: 20px;
    line-height: 20px;
}
.amgdprcookie-main-wrapper .amgdprcookie-btn-wrapper {
    display: flex;
}
.amgdprcookie-main-wrapper .amgdprcookie-btn {
    background: #ef7c4a;
    border: 0;
    border-radius: 6px;
    box-shadow: 0 3px 5px rgba(62, 40, 13, 0.15);
    color: #ffffff;
    font-size: 14px;
    padding: 7px 27px;
    text-transform: uppercase;
}
.amgdprcookie-main-wrapper .amgdprcookie-btn:first-child {
    margin: 0 15px;
}
.amgdprcookie-main-wrapper .amgdprcookie-btn:hover {
    background: #eb5a1b;
}
.amgdprcookie-main-wrapper .amgdprcookie-btn:focus,
.amgdprcookie-main-wrapper .amgdprcookie-btn:active {
    box-shadow: 0 0 3px 1px #00699d;
}
.amgdprcookie-cookie-container .amgdprcookie-link {
    color: #2977c2;
    cursor: pointer;
    float: right;
    font-size: 12px;
    letter-spacing: .3px;
    text-transform: capitalize;
}
.amgdprjs-bar-template {
    display: block !important;
    position: fixed;
    /* added to support older browsers */
    position: sticky;
}
.amgdprjs-bar-template .amgdprcookie-buttons-block .amgdprcookie-button {
    margin-right: 5px;
}
.amgdprjs-bar-template.-popup {
    left: 50%;
    margin: 0 auto;
    max-width: 740px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
}
.amgdprjs-bar-template.-popup:before {
    background-color: #0a6c9f;
    content: '';
    height: 100%;
    width: 100%;
}
.amgdprjs-bar-template.-popup .amgdprcookie-bar-container {
    padding: 50px;
}
.amgdprjs-bar-template.-popup .amgdprcookie-bar-block {
    width: 100%;
}
.amgdprjs-bar-template .amgdprcookie-buttons-block {
    display: flex;
    justify-content: center;
}
.amgdprcookie-groups-modal .modal-inner-wrap {
    border-radius: 4px;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, 0.06);
    max-width: 740px;
}
.amgdprcookie-groups-modal.-table .modal-inner-wrap {
    max-width: 1290px;
    min-width: 700px;
    overflow-x: auto;
}
.amgdprcookie-groups-modal .modal-title {
    border-bottom: none;
    font-size: 20px;
    font-weight: bold;
}
.amgdprcookie-groups-modal .action-close:focus {
    box-shadow: 0 0 3px 1px #00699d;
}
.amgdprcookie-groups-modal .amgdprcookie-form {
    padding: 0 10px;
}
.amgdprcookie-groups-modal .amgdprcookie-done {
    background-color: #4670c9;
    border-radius: 3px;
    box-shadow: none;
    color: #ffffff;
    font-weight: bold;
    letter-spacing: 1.35px;
    text-transform: uppercase;
    transition: background-color 0.3s ease-out;
    width: 100%;
}
.amgdprcookie-groups-modal .amgdprcookie-done:hover {
    background-color: #1357e6;
}
.amgdprcookie-groups-modal .amgdprcookie-done:focus {
    background-color: #1357e6;
    box-shadow: 0 0 3px 1px #00699d;
}
.amgdprcookie-groups-modal:not(.-table) .modal-footer {
    padding-top: 0;
}
.amgdprcookie-groups-modal .amgdprcookie-form-container,
.amgdprcookie-groups-modal .amgdprcookie-tbody {
    overflow: auto;
    scrollbar-color: #e0e0e0 #ffffff;
    scrollbar-width: thin;
    scrollbar-face-color: #e0e0e0;
    scrollbar-highlight-color: #e0e0e0;
    scrollbar-shadow-color: #e0e0e0;
    scrollbar-track-color: #ffffff;
    display: block;
    max-height: 500px;
    overflow-y: auto;
}
.amgdprcookie-groups-modal .amgdprcookie-form-container::-webkit-scrollbar,
.amgdprcookie-groups-modal .amgdprcookie-tbody::-webkit-scrollbar {
    width: 6px;
}
.amgdprcookie-groups-modal .amgdprcookie-form-container::-webkit-scrollbar-track,
.amgdprcookie-groups-modal .amgdprcookie-tbody::-webkit-scrollbar-track {
    background-color: #ffffff;
    box-shadow: none;
}
.amgdprcookie-groups-modal .amgdprcookie-form-container::-webkit-scrollbar-thumb,
.amgdprcookie-groups-modal .amgdprcookie-tbody::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #e0e0e0;
}
.amgdprcookie-bar-container {
    background: #ffffff;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 30px 10px;
    z-index: 10;
}
.amgdprcookie-bar-container.modal-popup {
    bottom: unset;
    left: unset;
    overflow-y: unset;
    pointer-events: auto;
    position: unset;
    right: unset;
    top: unset;
    transition: unset;
}
.amgdprcookie-bar-container .amgdprcookie-buttons-block {
    display: flex;
    flex-direction: column;
}
.amgdprcookie-bar-container .amgdprcookie-policy {
    margin-bottom: 20px;
    max-height: 30vh;
    max-width: 640px;
    overflow-y: auto;
}
.amgdprcookie-bar-container .amgdprcookie-button:first-child {
    margin-bottom: 10px;
}
.amgdprcookie-bar-container .amgdprcookie-buttons-block .amgdprcookie-button {
    margin-bottom: 0;
    padding: 10px 30px;
}
.amgdprcookie-bar-container .action-close {
    line-height: 14px;
    right: 10px;
    top: 10px;
}
.amgdprcookie-bar-container .action-close:focus,
.amgdprcookie-bar-container .action-close:active {
    box-shadow: 0 0 3px 1px #00699d;
    opacity: .9;
}
.amgdprcookie-buttons-block {
    display: flex;
    flex-direction: column;
}
.amgdprcookie-buttons-block.-settings {
    padding-bottom: 30px;
}
.amgdprcookie-buttons-block .amgdprcookie-button {
    background: #ffffff;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    color: #4670c9;
    font-weight: bold;
    letter-spacing: 1.35px;
    margin-top: 15px;
    padding: 10px 15px;
    text-transform: uppercase;
    transition: opacity 0.3s ease, color 0.3s ease-out, background-color 0.3s ease-out, border 0.3s ease-out;
}
.amgdprcookie-buttons-block .amgdprcookie-button:hover {
    opacity: .9;
}
.amgdprcookie-buttons-block .amgdprcookie-button:active,
.amgdprcookie-buttons-block .amgdprcookie-button:focus {
    box-shadow: 0 0 3px 1px #00699d;
    opacity: .9;
}
.amgdprcookie-buttons-block .amgdprcookie-button.-allow,
.amgdprcookie-buttons-block .amgdprcookie-button.-save {
    background: #4670c9;
    color: #ffffff;
    flex-basis: 33%;
}
.amgdprcookie-modal-template {
    background: #ffffff;
    margin-left: 5px;
    overflow-y: auto;
}
.amgdprcookie-modal-template .action-close {
    background: #ffffff;
    line-height: 14px;
    margin: 0;
    padding: 8px;
    right: 0;
    top: 0;
    z-index: 1;
}
.amgdprcookie-modal-template .action-close:focus,
.amgdprcookie-modal-template .action-close:active {
    background: #ffffff;
    box-shadow: 0 0 3px 1px #00699d;
    opacity: .9;
}
.amgdprcookie-modal-template .action-close:hover {
    background: #ffffff;
}
.amgdprcookie-modal-template .amgdprcookie-button.-allow:not(.-save) {
    background: inherit;
    color: #4670c9;
    margin-bottom: 0;
}
.amgdprcookie-modal-container .modal-content {
    padding: 0;
}
.amgdprcookie-groups-container {
    box-shadow: 0 -2px 18px rgba(0, 0, 0, 0.06);
    direction: rtl;
    height: auto;
    overflow-y: scroll;
}
.amgdprcookie-policy-container {
    bottom: 0;
    box-shadow: 0 -2px 18px rgba(0, 0, 0, 0.06);
    padding: 24px;
    position: absolute;
}
.amgdprcookie-text-container .amgdprcookie-policy {
    display: inline;
}
.amgdprcookie-modal-container.modal-popup.modal-slide {
    left: 0;
    pointer-events: none;
}
.amgdprcookie-modal-container .modal-inner-wrap {
    height: 100%;
    margin: 0;
    position: relative;
}
.amgdprcookie-modal-container .modal-inner-wrap .modal-header {
    position: absolute;
    right: 0;
}
.amgdprcookie-modal-container .modal-inner-wrap .action-close {
    margin: 5px;
    padding: 0;
}
.amgdprcookie-modal-container .modal-inner-wrap .action-close:before {
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSI4IiBzdHJva2U9IiNDQUNBQ0EiLz48cGF0aCBkPSJNNS4yNSA1LjI1bDYuNSA2LjVtMC02LjVsLTYuNSA2LjUiIHN0cm9rZT0iI0NBQ0FDQSIvPjwvc3ZnPg==') no-repeat center;
    content: '';
    height: 25px;
    width: 25px;
}
.amgdprcookie-modal-container .modal-inner-wrap .action-close:focus {
    box-shadow: 0 0 3px 1px #00699d;
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container {
    overflow: auto;
    scrollbar-color: #e8e8e8 #ffffff;
    scrollbar-width: thin;
    scrollbar-face-color: #e8e8e8;
    scrollbar-highlight-color: #e8e8e8;
    scrollbar-shadow-color: #e8e8e8;
    scrollbar-track-color: #ffffff;
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container::-webkit-scrollbar {
    width: 4px;
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container::-webkit-scrollbar-track {
    background-color: #ffffff;
    box-shadow: none;
}
.amgdprcookie-modal-container .modal-inner-wrap .amgdprcookie-groups-container::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #e8e8e8;
}
.amgdprcookie-settings-form .amgdprcookie-toggle-cookie .amgdprcookie-input.disabled + .amgdprcookie-label {
    background: #e8e8e8;
}
.amgdprcookie-groups-modal .amgdprcookie-table .amgdprcookie-row,
.amgdprcookie-groups-modal .amgdprcookie-table thead tr,
.amgdprcookie-groups-modal .amgdprcookie-table thead {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.amgdprcookie-groups-modal .amgdprcookie-cell.-name {
    word-wrap: break-word;
}
.amgdprcookie-modal-container .amgdprcookie-modal-template {
    height: 100%;
    margin-left: 0;
    min-width: 400px;
    pointer-events: auto;
    position: relative;
    width: 20%;
    z-index: 900;
}
.ammodals-overlay {
    background-color: rgba(51, 51, 51, 0.55);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 89;
}
