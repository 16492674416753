@import 'tab-variables';

.tab {
    padding: $tab__padding;

    @include mq($screen-l) {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    &__title {
        position: relative;
        display: $tab__title-display;
        width: $tab__title-width;
        height: $tab__title-size;
        padding: $tab__title-padding;
        border: $tab__title-border;
        border-width: $tab__title-border-width;
        background: $tab__title-background;
        color: $tab__title-color;
        font-family: $tab__title-font-family;
        font-size: $tab__title-font-size;
        font-weight: $tab__title-font-weight;
        text-align: $tab__title-text-align;
        text-transform: $tab__title-text-transform;
        transition: $tab__title-transition;
        cursor: pointer;

        @include mq($screen-l) {
            max-width: $tab__title-max-width\@large;
        }

        @include mq($screen-l) {
            flex: $tab__title-flex\@large;
            order: -1;
        }

        .tab__icon {
            fill: $tab__icon-fill;
            pointer-events: none;
        }

        &:hover,
        &.focus-visible,
        &--active {
            background: $tab__title-background--active;
            color: $tab__title-color--active;
            font-weight: $tab__title-font-weight--active;
            z-index: $tab__title-z-index--active;

            @include mq($screen-l) {
                z-index: $tab__title-z-index--active\@large;
            }

            .tab__icon {
                fill: $tab__icon-fill--active;
            }
        }

        &--active {
            .tab__icon {
                transform: $tab__icon-transform--active;
            }
        }

        .counter {
            &:before {
                content: "(";
            }

            &:after {
                content: ")";
            }
        }
    }


    &__icon {
        display: $tab__icon-display;
        position: $tab__icon-position;
        top: 0;
        bottom: 0;
        right: $tab__icon-right;
        margin: $tab__icon-margin;

        @include mq($screen-l) {
            display: $tab__icon-display\@large;
        }
    }

    &__content {
        position: relative;
        display: none;
        z-index: $tab__content-z-index;
        width: $tab__content-width;
        padding: $tab__content-padding;
        line-height: $tab__content-line-height;
        opacity: 0;
        overflow: hidden;

        @include mq($screen-m) {
            padding: $tab__content-padding\@medium;
            flex-basis: 100%;
            z-index: $tab__content-z-index\@medium;
        }

        @include mq($screen-l) {
            padding: $tab__content-padding\@large;
        }

        @include mq($screen-xl) {
            padding: $tab__content-padding\@extra-large;
        }

        &--active {
            display: block;
            opacity: 1;
            z-index: 1;

            &:not(:nth-last-child(1)) {
                border: $tab__content-border--active;
                border-width: $tab__content-border-width--active;

                @include mq($screen-l) {
                    border: $tab__content-border--active\@large;
                    border-width: $tab__content-border-width--active;
                }
            }
        }

        &--secondary {
            padding: $tab__content-padding--secondary;
        }

        // It's necessary for display .tooltip in tab in PDP
        &.additional {
            overflow: initial;
        }
    }
}
