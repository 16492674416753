$image__width                       : 100% !default;
$image__transition                  : $transition-fade !default;
$image__wrapper-background-color    : $white !default;

$image__picture-lazyload-min-height : 56px !default;
$image__picture-lazyload-bg-image   : url('../images/loader-svg.svg') !default;

.image {
    display: block;
    width: $image__width;
    max-width: $image__width;
    transition: $image__transition;

    &.lazyload,
    &.lazyloading {
        opacity: 0;
    }

    &.lazyloaded {
        opacity: 1;
    }
}

img,
picture {
    @extend .image;
}

.lazyload-wrapper {
    position: relative;
    background-color: $image__wrapper-background-color;
    transition: $transition-base;
}

picture > img.lazyload,
picture > img.lazyloading {
    min-height: $image__picture-lazyload-min-height;
    opacity: 1;
    background-image: $image__picture-lazyload-bg-image;
    background-size: $image__picture-lazyload-min-height;
    background-repeat: no-repeat;
    background-position: center;
}
