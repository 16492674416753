$dashboard-nav__mobile-border                     : $border-base !default;
$dashboard-nav__mobile-border-width               : 0 0 $border-width-base !default;
$dashboard-nav__mobile-padding                    : $spacer--semi-medium 0 !default;
$dashboard-nav__mobile-font-weight                : $font-weight-bold !default;

$dashboard-nav__item-padding                      : 0 !default;
$dashboard-nav__item-transition                   : $transition-base !default;
$dashboard-nav__item-font-size\@large             : $font-size-medium !default;

$dashboard-nav__item-link-padding                 : $spacer--medium $spacer--extra-large $spacer--medium $spacer--medium !default;
$dashboard-nav__item-link-transform-after         : rotate(-45deg) translateY(-50%) !default;
$dashboard-nav__item-link-top-after               : 50% !default;

$dashboard-nav__link-padding                      : $spacer--medium !default;
$dashboard-nav__link-text-decoration              : none !default;
$dashboard-nav__link-bg-current                   : $gray-light !default;
$dashboard-nav__link-font-weight-current          : $font-weight-bold !default;
$dashboard-nav__link-color-current-hover          : initial !default;
$dashboard-nav__link-text-decoration-current-hover: none !default;
$dashboard-nav__link-bg-hover                     : $gray-light !default;
$dashboard-nav__link-font-weight-hover            : $font-weight-base !default;
$dashboard-nav__link-color-hover                  : initial !default;
$dashboard-nav__link-text-decoration-hover        : none !default;
$dashboard-nav__link-font-size                    : $font-size-base !default;

$dashboard-nav__actions-margin                    : $spacer--large 0 0 !default;
$dashboard-nav__actions-margin\@large             : $spacer--medium 0 0 !default;

$dashboard-nav__section-title-margin              : 0 !default;
$dashboard-nav__section-title-letter-spacing      : 1px !default;
$dashboard-nav__section-title-font-family         : $font-family-base !default;
$dashboard-nav__section-title-font-size           : $font-size-medium !default;
$dashboard-nav__section-info-margin               : $spacer--semi-medium 0 0 !default;

$dashboard-nav__button-min-width\@medium          : 320px !default;

%arrow:after {
    content: '';
    position: absolute;
    top: 35%;
    right: 20px;
    height: 10px;
    width: 10px;
    margin-top: 0;
    border-style: solid;
    border-color: transparent $color-secondary $color-secondary transparent;
    border-width: 2px;
    border-radius: 2px;
    pointer-events: none;
    transform: rotate(45deg);
    transition: $select__transition;
}

%arrow-active:after {
    top: 50%;
    border-color: transparent $color-secondary $color-secondary transparent;
    margin-top: 0;
    transform: rotate(225deg);
}
