$store-locator__border                            : $border-width-base $border-style-base $color-primary !default;

$store-locator__input-border--active              : $border-base !default;
$store-locator__action-height                     : 48px !default;
$store-locator__action-icon-size                  : 24px !default;
$store-locator__button-bg                         : $color-primary !default;
$store-locator__button-bg--active                 : $color-primary !default;

$store-locator__label-font-size                   : $font-size-small !default;
$store-locator__label-font-size\@medium           : $font-size-base !default;

$store-locator__filter-action-justify             : flex-end !default;
$store-locator__filter-action-align               : center !default;
$store-locator__filter-action-padding             : $spacer $spacer--medium !default;

$store-locator__search-bg                         : transparent !default;
$store-locator__search-border                     : $border-base !default;
$store-locator__search-border-width               : $border-width-base !default;
$store-locator__search-margin                     : 0 0 $spacer--medium 0 !default;
$store-locator__search-padding                    : 0 0 $spacer--medium 0 !default;
$store-locator__search-title-margin               : 0 0 $spacer--medium 0 !default;

$store-locator__store-description-bg              : $gray-lighter !default;
$store-locator__store-description-bg--active      : $gray-lighter !default;
$store-locator__store-description-color--active   : $color-primary !default;
$store-locator__store-description-margin          : $spacer 0 0 0 !default;
$store-locator__store-description-border--active  : 2px $border-style-base $border-color-base !default;

$store-locator__store-schedule-margin             : $spacer--medium 0 0 0 !default;
$store-locator__store-schedule-color--active      : $color-primary !default;
$store-locator__store-schedule-bg--active         : $gray !default;
$store-locator__store-schedule-label-bg--active   : $gray !default;
