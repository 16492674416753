$gallery__width                             : 100% !default;
$gallery__padding                           : 0 !default;
$gallery__margin                            : 0 !default;

$gallery--icon-arrow-color                  : $color-primary !default;
$gallery__icon-arrow-color-hover            : $color-secondary !default;

$gallery__nav-margin-right--vertical\@medium: $spacer--medium !default;
$gallery__nav-margin--vertical\@medium      : 0 $gallery__nav-margin-right--vertical\@medium 0 0 !default;
$gallery__nav-margin-right--vertical\@large : $spacer--semi-large !default;
$gallery__nav-margin--vertical\@large       : 0 $gallery__nav-margin-right--vertical\@large 0 0 !default;

$gallery__stage-margin                      : 0 0 $spacer--medium 0 !default;

$gallery__thumb-border                      : none !default;
$gallery__thumb-border--active              : 2px solid $color-primary !default;
$gallery__thumb-spacing                     : $spacer--medium !default;
$gallery__thumb-margin                      : 0 $gallery__thumb-spacing 0 0 !default;
$gallery__thumb-margin--vertical            : 0 0 $gallery__thumb-spacing 0 !default;
$gallery__thumb-width                       : 64px !default;
$gallery__thumb-width\@medium               : 64px !default;

// dots
$gallery__thumb-margin--dots                : 0 5px !default;
$gallery__thumb-dot-size--dots              : 6px !default;
$gallery__thumb-dot-bg-color--dots          : $gray !default;
$gallery__thumb-dot-bg-color-active--dots   : $color-primary !default;
$gallery__thumb-dot-border-radius--dots     : 100% !default;
$gallery__thumb-border-active--dots         : 0 !default;

$gallery__placeholder-transform             : translate(calc(-50% + #{$spacer--medium}), 0) !default;
