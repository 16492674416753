@import 'gift-registry-variables';

.giftregistry {
    &__item-image {
        max-width: $giftregistry__item-image-max-width;
        margin: $giftregistry__item-image-margin;
    }

    &__qty-field {
        min-width: $giftregistry__qty-field-min-width;
    }
}
