$button__min-size: 48px !default;
$button__padding: 0 $spacer--large !default;
$button__background: $color-primary !default;
$button__background-hover: initial !default;
$button__border: none !default;
$button__border-radius: 0 !default;
$button__outline: $outline-base !default;
$button__font-family: $font-family-base !default;
$button__font-size: $font-size-base !default;
$button__font-weight: $font-weight-bold !default;
$button__line-height: 1 !default;
$button__text-color: $white !default;
$button__text-transform: uppercase !default;
$button__text-decoration: none !default;
$button__text-decoration-hover: none !default;
$button__transform: perspective(1px) translateZ(0) !default;
$button__transition: all 0.5s ease-in-out !default;

// before used in hover state
$button__before-display: block !default;
$button__before-content: "" !default;
$button__before-width: 100% !default;
$button__before-position-left: 0 !default;
$button__before-position-top: 0 !default;
$button__before-position-bottom: 0 !default;
$button__before-background: $color-primary !default;
$button__before-transform: scaleX(0) !default;
$button__before-transform-origin: 0 50% !default;

// before hover
$button__before-transform-hover: scaleX(1) !default;
$button__before-display-hover: block !default;

// after used in hover state
$button__after-display: block !default;
$button__after-content: url("../images/icons/arrow-right-light.svg") !default;
$button__after-position-left: 20% !default;
$button__after-position-top: 50% !default;
$button__after-z-index: 1 !default;
$button__after-opacity: 0 !default;
$button__after-transform: translateY(-50%) !default;
$button__after-transform-origin: center !default;

// after hover
$button__after-display-hover: block !default;
$button__after-position-left-hover: calc(50% - 12px) !default;
$button__after-opacity-hover: 1 !default;

// disabled
$button__background-disabled: $gray !default;

// Secondary
$button__color--secondary: $color-primary !default;
$button__background--secondary: $white !default;
$button__border--secondary: 2px solid $button__color--secondary !default;

// Secondary hover
$button__color-hover--secondary: $white !default;
$button__background-hover--secondary: $white !default;
$button__before-z-index-hover--secondary: -1 !default;
$button__after-display-hover--secondary: none !default;

// Secondary light
$button__text-transform--light: none !default;
$button__font-weight--light: $font-weight-normal !default;
$button__text-transform--light: none !default;

// Button Link
$button__color--link: $color-primary !default;
$button__background--link: transparent !default;
$button__text-transform--link: none !default;
$button__text-decoration--link: underline !default;

// Button Link hover
$button__color-hover--link: $blue !default;
$button__background-hover--link: transparent !default;
$button__before-display-hover--link: none !default;
$button__after-display-hover--link: none !default;

// Button icon
$button__padding--icon: 0 $spacer !default;
$button__icon-size: 24px !default;
$button__icon-fill: $color-primary !default;
$button__icon-transition: $transition-base !default;
$button__icon-fill-hover--icon: $white !default;
$button__bg--icon: $white !default;
$button__icon-z-index: $z-index-low !default;
$button__text-margin--icon: 0 $spacer !default;
$button__text-z-index: $z-index-low !default;
$button__text-color--icon: $color-primary !default;
$button__text-color-hover--icon: $white !default;
$button__font-weight--icon: $font-weight-normal !default;
$button__text-transform--icon: none !default;
$button__background-hover--icon: initial !default;

// Button icon light
$button__icon-fill-hover--icon-light: $color-primary !default;
$button__before-background--icon-light: $gray-light !default;
$button__text-color-hover--icon-light: $color-primary !default;
$button__background-hover--icon-light: initial !default;

// Button icon border
$button__border--icon-border: $border-width-base $border-style-base
    $color-primary !default;
$button__border-hover--icon-border: $button__border--icon-border !default;
$button__background--icon-border: $button__bg--icon !default;
$button__background-hover--icon-border: $button__background-hover--icon !default;

// Button rotate-icon
$button__icon-fill-hover--rotate-icon: $color-primary !default;
$button__transform-hover--rotate-icon: rotate(180deg) !default;
$button__before-background--rotate-icon: $gray-light !default;
$button__background-hover--rotate-icon: initial !default;

// Button add-to
// icon & text
$button__icon-display--add-to: block !default;
$button__icon-display--add-to\@medium: none !default;
$button__text-display--add-to: none !default;
$button__text-display--add-to\@medium: block !default;
$button__before-display--add-to: block !default;
$button__after-display--add-to: none !default;
$button__after-display--add-to\@large: block !default;
$button__after-content-icon--add-to: url("../images/icons/plus-light.svg") !default;
$button__after-position-left--add-to: calc(50% - 12px) !default;
$button__after-position-top--add-to: auto !default;
$button__after-transform--add-to: translateX(-150%) !default;
$button__after-transform-hover--add-to: translateY(0) !default;
$button__after-content-text--add-to: url("../images/icons/shopping-cart-light.svg") !default;
$button__background-hover--add-to: $color-primary !default;
$button__before-display-hover--add-to: none !default;
$button__before-display-hover--add-to\@large: block !default;
$button__after-display-hover--add-to: none !default;
$button__after-display-hover--add-to\@large: block !default;

$button__background--icon-fill: transparent !default;
$button__icon-after-mask--icon-fill: "../images/icons/heart-fill.svg" !default;
$button__icon-after-mask-repeat--icon-fill: no-repeat !default;
$button__icon-after-mask-position--icon-fill: center / cover !default;
$button__icon-after-bg-size--icon-fill: 400% 400% !default;
$button__icon-after-bg-image--icon-fill: linear-gradient(
    to right,
    $color-primary 50%,
    transparent 50%
) !default;
$button__icon-after-bg-position--icon-fill: 100% 100% !default;
$button__icon-after-bg-position-hover--icon-fill: 0 100% !default;
$button__icon-after-transition--icon-fill: background 0.5s linear !default;
$button__icon-after-z-index--icon-fill: -1 !default;
$button__icon-after-oapcity--icon-fill: 1 !default;
$button__icon-fill-hover--icon-fill: $color-primary !default;
$button__before-background--icon-fill: transparent !default;
$button__background-hover--icon-fill: initial !default;
$button__icon-fill--add-to: $white !default;

%button-before:before {
    content: $button__before-content;
    display: $button__before-display;
    position: absolute;
    width: $button__before-width;
    left: $button__before-position-left;
    top: $button__before-position-top;
    bottom: $button__before-position-bottom;
    background-color: $button__before-background;
    transform: $button__before-transform;
    transform-origin: $button__before-transform-origin;
    transition: $button__transition;
    pointer-events: none;
}

%button-after:after {
    content: $button__after-content;
    display: $button__after-display;
    position: absolute;
    left: $button__after-position-left;
    top: $button__after-position-top;
    width: $button__icon-size;
    height: $button__icon-size;
    padding: $icon__padding;
    z-index: $button__after-z-index;
    opacity: $button__after-opacity;
    transform: $button__after-transform;
    transform-origin: $button__after-transform-origin;
    transition: $button__transition;
    pointer-events: none;
}
