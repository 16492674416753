// Colors
$white: #fff !default;
$black: #000 !default;
$red: #e62325 !default;
$orange: #fab216 !default;
$yellow: #ffd500 !default;
$green: #13b200 !default;
$blue: #0075e4 !default;
$bright-sky-blue: #00b8ff !default;
$teal: #0194ab !default;
$pink: #ff5b77 !default;
$purple: #613d7c !default;
$seafoam-blue: #77ccb9 !default;

// Grayscale colors
$gray-darkest: #545454 !default;
$gray-darker: #393243 !default;
$gray-dark: #6e7577 !default;
$gray: #d8d8d8 !default;
$gray-light: #eaeaea !default;
$gray-lighter: #fbfbfb !default;
$gray-lightest: #f7f7f9 !default;

$brotherstore: #0d2ea0 !default;

// Semantic color scheme

$color-primary: $gray-darkest !default;
$color-secondary: $gray-dark !default;
$color-success: $green !default;
$color-info: $blue !default;
$color-warning: $red !default;
$color-error: $red !default;
$color-inverse: $gray-dark !default;
$color-focus: $bright-sky-blue !default;

// Background
$bg-color-base: $white !default;
$bg-color-secondary: $gray-lighter !default;
$bg-color-dark: $gray-darkest !default;

// Border
$border-color-base: $gray !default;
$border-width-base: 1px !default;
$border-style-base: solid !default;
$border-color-secondary: $gray-dark !default;
$border-width-secondary: 1px !default;
$border-style-secondary: solid !default;

$border-base: $border-width-base $border-style-base $border-color-base !default;
$border-secondary: $border-width-secondary $border-style-secondary $border-color-secondary !default;

// Outline
$outline-base: none !default;

// Focus
$border-focus: 2px solid $color-focus !default;
$border-focus-dark: 2px solid $color-primary !default;

// Focus inline
$color-focus-inline: $color-primary !default;
$color-focus-inline-dark: $color-primary !default;
$bg-focus-inline: $color-focus !default;
$bg-focus-inline-dark: $color-primary !default;
$outline-focus-inline: 0 !default;
$text-decoration-focus-inline: underline !default;
$focus-outline-offset: -1px !default;
$focus-input-border: $border-focus-dark;

// Spacers
$spacer--extra-small: 4px !default;
$spacer: 8px !default;
$spacer--small: 12px !default;
$spacer--medium: 16px !default;
$spacer--semi-medium: 24px !default;
$spacer--large: 32px !default;
$spacer--semi-large: 40px !default;
$spacer--extra-large: 48px !default;

// Typography
$font-family-sans-serif: "Halvetica Neue", sans-serif !default;
$font-family-serif: "Playfair Display", serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-family-base: $font-family-sans-serif !default;
$font-family-secondary: $font-family-serif !default;

$font-color-base: $color-primary !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-base: $font-weight-normal !default;
$font-line-height: 1.6 !default;

$font-size-super-extra-large: 32px !default;
$font-size-extra-large: 24px !default;
$font-size-large: 18px !default;
$font-size-medium: 16px !default;
$font-size-base: 14px !default;
$font-size-small: 12px !default;
$font-size-extra-small: 10px !default;

// Z-index
$z-index-lowest: -1 !default;
$z-index-initial: auto !default;
$z-index-low: 1 !default;
$z-index-normal: 10 !default;
$z-index-high: 50 !default;
$z-index-highest: 99 !default; // reserved for modals - check _modal-variables.scss for more

// Layout
$max-content-width: 1328px !default;

// Layout - breakpoints
$screen-xs: 320px !default;
$screen-s: 480px !default;
$screen-m: 768px !default;
$screen-l: 1024px !default;
$screen-xl: 1200px !default;
$screen-xxl: 1600px !default;

// shadow
$shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !default;
$darken-layout-hover: rgba($black, 0.1) !default;

// animations
$animation-position-top: -300px !default;
$animation-position-right: -300px !default;
$animation-position-left: -300px !default;

// Components
$border-radius: 0 !default;
$form-elements-radius: 0 !default;
$form-elements-radius--small: 0 !default;
$form-elements-border-color: $border-color-secondary !default;
$form-element-border-width: 1px !default;
$form-element-border-style: solid !default;
$form-elements-border: $form-element-border-width $form-element-border-style $form-elements-border-color !default;
$form-elements-border-focus: 2px solid $color-primary !default;
$form-input-box-shadow-focus: none !default;
$transition-base: all 0.3s ease-in-out !default;
$transition-fade: opacity 0.25s linear !default;
