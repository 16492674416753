@import 'newsletter-variables';

.newsletter {
    display: flex;
    flex-direction: column;
    padding: $newsletter__padding;
    margin-bottom: 25px;

    // @include mq($screen-m) {
    //     flex-direction: row;
    // }

    &__heading {
        display: flex;
        // flex-flow: column nowrap;

        @include mq($screen-m) {
            margin: $newsletter__heading-margin\@medium;
        }

        @include mq($screen-xl) {
            margin: $newsletter__heading-margin\@extra-large;
        }
    }

    &__title {
        margin: $newsletter__title-margin;
        font-family: $newsletter__title-font-family;
        font-size: $newsletter__title-font-size;
        font-weight: $newsletter__title-font-weight;
        line-height: $newsletter__title-line-height;
        color: $newsletter__title-color;
    }

    &__subtitle {
        color: $newsletter__subtitle-color;
        font-family: $newsletter__subtitle-font-family;
    }

    &__controls {
        display: flex;
        margin: 0 auto;
        max-width: 524px;

        // @include mq($screen-m) {
        //     margin: $newsletter__controls-margin\@medium;
        // }

        // @include mq($screen-xl) {
        //     max-width: 460px;
        // }
    }

    &__button {
        min-width: $newsletter__button-min-width;
        padding: $newsletter__button-padding;
        align-self: flex-start;

        border: none;

        height: 50px;

        background: #0d2ea0;
        color: #fff !important;

        padding: 0 30px !important;

        animation: none;

        &:hover {
            color: white !important;
            background: #0d2ea0 !important;
        }

        &::after {
            display: none !important;
        }

        &::before {
            display: none !important;
        }

        &:disabled,
        &[disabled] {
            background: #0d2ea0 !important;

            &:hover {
                background: #0d2ea0 !important;
            }

            // .icon {
            //     fill: $search-form__button-icon-fill--disabled;
            // }
        }
    }

    &__input {
        flex-grow: 1;
        margin: 0;
    }

    &__field {
        border-right: none;
        height: 50px !important;
    }

    &__agreements {
        max-width: $newsletter__agreements-max-width;
        margin: $newsletter__agreements-margin;
    }

    &__form {
        flex-grow: 1;
        width: 100%;
    }

    .checkbox {
        &__label {
            padding: $newsletter__checkbox-label-padding;
            color: $newsletter__checkbox-label-color;
            font-family: $newsletter__checkbox-label-font-family;
            font-size: $newsletter__checkbox-label-font-size;
            line-height: $newsletter__checkbox-label-line-height;

            &:after {
                background-color: $newsletter__checkbox-before-background;
            }

            a {
                color: $newsletter__checkbox-label-link-color;
                font-family: $newsletter__checkbox-label-font-family;
                line-height: $newsletter__checkbox-label-line-height;

                &:hover,
                &.focus-visible {
                    color: $newsletter__checkbox-label-link-color--hover;
                }
            }
        }

        &__icon {
            fill: $newsletter__checkbox-icon-fill;
        }

        &__text {
            color: $newsletter__checkbox-text-color;
        }
    }

    .gdpr {
        margin: $newsletter__agreements-margin;
    }
}
