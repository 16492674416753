$catalog-event__display                      : flex !default;
$catalog-event__flex-direction               : column !default;
$catalog-event__justify-content              : center !default;
$catalog-event__align-items                  : center !default;
$catalog-event__width                        : 100% !default;
$catalog-event__max-width                    : $max-content-width !default;
$catalog-event__margin                       : 0 auto 72px !default;
$catalog-event__margin--slider-item          : 0 auto !default;
$catalog-event__background-color             : $gray-lightest !default;
$catalog-event__padding                      : $spacer--medium 0 !default;
$catalog-event__padding\@medium              : $spacer--large 0 !default;

$catalog-event__date-wrapper-display        : flex !default;
$catalog-event__date-wrapper-flex-direction : column !default;
$catalog-event__date-wrapper-align-items    : center !default;

$catalog-event__date-display                : flex !default;
$catalog-event__date-justify-content        : center !default;

$catalog-event__date-item-min-width         : 80px !default;
$catalog-event__date-item-min-width\@medium : 120px !default;
$catalog-event__date-item-text-align        : center !default;

$catalog-event__date-value-font-faily       : $font-family-serif !default;
$catalog-event__date-value-font-size        : $font-size-super-extra-large !default;
$catalog-event__date-value-font-size\@medium: 48px !default;

$catalog-event__date-label-color            : $color-primary !default;
$catalog-event__date-label-font-size        : $font-size-base !default;
$catalog-event__date-label-text-transform   : none !default;

$catalog-event__button-min-width            : 208px !default;
