.search-form {
    width: $search-form__width;
    align-self: $search-form__alignment;
    line-height: $font-line-height;
    margin: $search-form__margin;
    position: $search-form__position;
    position: relative !important;

    @include mq($screen-m) {
        position: $search-form__position\@medium;
    }

    &__wrapper {
        position: relative;
    }

    &__input-wrapper {
        position: relative;
    }

    &__input {
        width: 100%;
        -webkit-appearance: none; // sass-lint:disable-line no-vendor-prefixes
        margin-bottom: 80px;
        height: 64px !important;
        border: solid 1px #6d7173 !important;
    }

    &__button {
        position: absolute;
        top: $search-form__button-top;
        right: -3px;
        min-height: $search-form__button-height;
        margin: $search-form__button-margin;
        border: $search-form__button-border;
        border-width: $search-form__button-border-width;

        height: 62px;
        transform: translateX(-1px);

        background: #0d2ea0;
        color: white;

        padding: 0 30px !important;

        animation: none;

        &:hover {
            color: white !important;
            background: #0d2ea0 !important;
        }

        &::after {
            display: none !important;
        }

        &::before {
            display: none !important;
        }

        &:disabled,
        &[disabled] {
            background: #0d2ea0 !important;

            &:hover {
                background: #0d2ea0 !important;
            }

            // .icon {
            //     fill: $search-form__button-icon-fill--disabled;
            // }
        }
    }
}

#search_autocomplete {
    top: 70% !important;
    z-index: 1000 !important;
}
