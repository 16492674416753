@import 'store-locator-variables';

.store-locator {
    &__button-search {
        top: 0;
    }

    &__today-label {
        font-size: $store-locator__label-font-size;
        @include mq($screen-m) {
            font-size: $store-locator__label-font-size\@medium;
        }
    }

    &__filter-actions {
        display: flex;
        flex-wrap: nowrap;
        justify-content: $store-locator__filter-action-justify;
        align-items: $store-locator__filter-action-align;
        padding: $store-locator__filter-action-padding;
    }

    &__stores {
        @include mq($screen-m) {
            flex-direction: column;
        }
        @include mq($screen-xl) {
            flex-direction: row;
        }
    }

    // adjust store-locator styles
    .amlocator-search-container
    .amlocator-title {
        margin: $store-locator__search-title-margin;
        .amlocator-link {
            word-break: keep-all;
        }
    }

    .amlocator-search-container {
        border: $store-locator__search-border-width;
        background-color: $store-locator__search-bg;
        border-width: $store-locator__search-border-width;
        padding: $store-locator__search-padding;
        margin: $store-locator__search-margin;
    }

    .amlocator-filters-container {
        background-color: transparent;
    }

    .amlocator-store-desc {
        background-color: $store-locator__store-description-bg;
        &.-active {
            background-color: $store-locator__store-description-bg--active;
            color: $store-locator__store-description-color--active;
            border: $store-locator__store-description-border--active;

            .amlocator-title {
                .amlocator-link {
                    color: $store-locator__store-description-color--active;
                }

            }

            .amlocator-schedule-table {
                color: $store-locator__store-schedule-color--active;
            }

            & + .amlocator-schedule-container {
                background-color: $store-locator__store-schedule-bg--active;
            }

            .amlocator-today {
                color: $store-locator__store-schedule-color--active;
                background-color: $store-locator__store-schedule-label-bg--active;
                @include mq($screen-m, $screen-xl) {
                    flex-wrap: wrap;
                }

                .amlocator-time {
                    @include mq($screen-m, $screen-xl) {
                        width: 100%;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .amlocator-schedule-table {
        margin: $store-locator__store-schedule-margin;
    }

    // maps popup
    .amlocator-description {
        margin: $store-locator__store-description-margin;
    }
    .amlocator-info-popup {
        font-weight: $font-weight-base;
        font-size: $font-size-base;
        line-height: $font-line-height;
    }
    .amlocator-description {
        p {
            font-size: $font-size-base;
        }
    }

    .input__field {
        font-size: $font-size-base;
    }
    // to overwrite amasty styles loaded in php script :/
    &.amlocator-main-container {
        .amlocator-button {
            color: $white;
            background-color: $store-locator__button-bg;
            border: $store-locator__border;

            &:hover,
            &:focus,
            &.focus-visible {
                background-color: $store-locator__button-bg--active;
            }
        }
        .amlocator-search-container .amlocator-button.-nearby {
            color: $white;
            background-color: $store-locator__button-bg;

            &:hover,
            &:focus,
            &.focus-visible {
                background-color: $store-locator__button-bg--active;
            }
        }
    }

    .amlocator-current-location .amlocator-text {
        height: $store-locator__action-height;

        &:hover,
        &:focus,
        &.ficus-visible {
            border: $store-locator__input-border--active;
        }
    }
}

.amlocator-schedule-container {
    background-color: $gray-lighter;
    .amlocator-today {
        min-height: $store-locator__action-height;
        align-items: center;
        &:before {
            display: none;
        }
    }

}
