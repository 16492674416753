$quantity-update__input-border-style               : solid !default;
$quantity-update__input-border-color               : $border-color-base !default;
$quantity-update__input-border-width               : 1px 0 !default;
$quantity-update__input-border-width--error        : 1px !default;
$quantity-update__input-border-radius              : 0 !default;
$quantity-update__input-width                      : 70px !default;
$quantity-update__input-height                     : 56px !default;
$quantity-update__input-font-size                  : $font-size-large !default;
$quantity-update__input-color                      : $color-primary !default;

$quantity-update__button-size                      : 56px !default;
$quantity-update__button-margin                    : 0 !default;
$quantity-update__button-border                    : $border-base !default;

$quantity-update__max-width                        : 100% !default;
$quantity-update__max-width\@medium                : $quantity-update__input-width + (2 * $quantity-update__button-size) !default;
$quantity-update__width                            : 100%;

$quantity-update__border--active                   : $border-width-base $border-style-base $color-primary !default;
$quantity-update__transition                       : $transition-base !default;

$quantity-update__mage-error-bottom                : -$spacer--semi-medium !default;
$quantity-update__mage-error-width                 : 250px !default;
